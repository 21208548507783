import { LocationMarkerIcon, MapIcon } from '@heroicons/react/outline'
import { UseFormReturnType } from '@mantine/form'
import { classNames } from '@utils/classNames'
import { useTranslation } from 'next-i18next'
import type { FC } from 'react'

import { FormCountrySelect, FormInput } from '@people/ui/form'
import { PostBoxIcon } from '@people/ui/icons'

export interface SingleLocation {
  id: string
  streetAddress: string
  postalCode: string
  city: string
  countryCode: string
  latitude: number
  longitude: number
  name: string
}

export interface LocationAddressFormType {
  locations: SingleLocation[]
}

export interface LocationAddressFormProps {
  className?: string
  idx: number
  form: UseFormReturnType<LocationAddressFormType>
  isIniatialDataLoading: boolean
  clearLabels?: boolean
  classNameInputs?: string
  isHQ?: boolean
  isPopup?: boolean
  disabled: boolean
}

export const LocationAddressForm: FC<LocationAddressFormProps> = ({
  className,
  idx,
  form,
  isIniatialDataLoading,
  clearLabels,
  classNameInputs,
  isHQ,
  isPopup,
  disabled,
}) => {
  const { t } = useTranslation('common')
  return (
    <div className={classNames(className ?? '', 'px-6 pt-6 pb-5')}>
      <div
        className={classNames(
          'grid grid-cols-2 gap-5 sm:gap-6',
          classNameInputs || '',
        )}
      >
        {!isHQ && (
          <div className="col-span-2">
            <FormInput
              label={t('locationName')}
              type="text"
              required
              clearLabel={clearLabels}
              placeholder=""
              disabled={disabled || isIniatialDataLoading}
              {...form.getInputProps(`locations.${idx}.name`)}
            />
          </div>
        )}
        <div
          className={classNames(
            isPopup ? 'col-span-2' : 'col-span-2 sm:col-span-1',
          )}
        >
          <FormInput
            label={t('streetAddress')}
            type="text"
            required
            clearLabel={clearLabels}
            placeholder=""
            disabled={disabled || isIniatialDataLoading}
            {...form.getInputProps(`locations.${idx}.streetAddress`)}
          >
            <MapIcon className="h-5 w-5 text-slate-400" />
          </FormInput>
        </div>
        <div
          className={classNames(
            isPopup ? 'col-span-2' : 'col-span-2 sm:col-span-1',
          )}
        >
          <FormInput
            label={t('postalCode')}
            type="text"
            required
            clearLabel={clearLabels}
            placeholder=""
            disabled={disabled || isIniatialDataLoading}
            {...form.getInputProps(`locations.${idx}.postalCode`)}
          >
            <PostBoxIcon className="h-5 w-5 text-slate-400" />
          </FormInput>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormInput
            label={t('city')}
            type="text"
            required
            clearLabel={clearLabels}
            placeholder=""
            disabled={disabled || isIniatialDataLoading}
            {...form.getInputProps(`locations.${idx}.city`)}
          >
            <LocationMarkerIcon className="h-5 w-5 text-slate-400" />
          </FormInput>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormCountrySelect
            label={t('country')}
            required
            clearLabel={clearLabels}
            placeholder=""
            disabled={disabled || isIniatialDataLoading}
            {...form.getInputProps(`locations.${idx}.countryCode`)}
          />
        </div>
      </div>
    </div>
  )
}
