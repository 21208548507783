import type { FC } from 'react'
import IconProps from './IconProps'

export const PostBoxIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 10.0001H8.33333C8.55435 10.0001 8.76631 9.91228 8.92259 9.756C9.07887 9.59972 9.16667 9.38776 9.16667 9.16675C9.16667 8.94573 9.07887 8.73377 8.92259 8.57749C8.76631 8.42121 8.55435 8.33342 8.33333 8.33342H6.66667C6.44565 8.33342 6.23369 8.42121 6.07741 8.57749C5.92113 8.73377 5.83333 8.94573 5.83333 9.16675C5.83333 9.38776 5.92113 9.59972 6.07741 9.756C6.23369 9.91228 6.44565 10.0001 6.66667 10.0001ZM14.1667 5.00008H10V3.33341H10.8333C11.0543 3.33341 11.2663 3.24562 11.4226 3.08934C11.5789 2.93306 11.6667 2.7211 11.6667 2.50008C11.6667 2.27907 11.5789 2.06711 11.4226 1.91083C11.2663 1.75455 11.0543 1.66675 10.8333 1.66675H9.16667C8.94565 1.66675 8.73369 1.75455 8.57741 1.91083C8.42113 2.06711 8.33333 2.27907 8.33333 2.50008V5.00008H5.83333C4.94928 5.00008 4.10143 5.35127 3.47631 5.97639C2.85119 6.60151 2.5 7.44936 2.5 8.33342V13.3334C2.5 13.5544 2.5878 13.7664 2.74408 13.9227C2.90036 14.079 3.11232 14.1667 3.33333 14.1667H8.33333V17.5001C8.33333 17.7211 8.42113 17.9331 8.57741 18.0893C8.73369 18.2456 8.94565 18.3334 9.16667 18.3334C9.38768 18.3334 9.59964 18.2456 9.75592 18.0893C9.9122 17.9331 10 17.7211 10 17.5001V14.1667H16.6667C16.8877 14.1667 17.0996 14.079 17.2559 13.9227C17.4122 13.7664 17.5 13.5544 17.5 13.3334V8.33342C17.5 7.44936 17.1488 6.60151 16.5237 5.97639C15.8986 5.35127 15.0507 5.00008 14.1667 5.00008ZM10.8333 8.33342V12.5001H4.16667V8.33342C4.16667 7.89139 4.34226 7.46746 4.65482 7.1549C4.96738 6.84234 5.39131 6.66675 5.83333 6.66675H11.3C10.997 7.17008 10.8358 7.74595 10.8333 8.33342ZM15.8333 12.5001H12.5V8.33342C12.5 7.89139 12.6756 7.46746 12.9882 7.1549C13.3007 6.84234 13.7246 6.66675 14.1667 6.66675C14.6087 6.66675 15.0326 6.84234 15.3452 7.1549C15.6577 7.46746 15.8333 7.89139 15.8333 8.33342V12.5001Z"
        fill="#94A3B8"
      />
    </svg>
  )
}

export default PostBoxIcon
