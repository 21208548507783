import React from 'react'

import { classNames } from '../utils/classNames'
import { FormStyles } from './FormStyles'

export interface FormSelectProps
  extends React.InputHTMLAttributes<HTMLSelectElement> {
  label?: string
  labelClassName?: string
  clearLabel?: boolean
  icon?: React.ReactNode
  iconClassName?: string
}

export const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      id,
      label,
      labelClassName,
      children,
      clearLabel,
      icon,
      className,
      iconClassName,
      ...other
    },
    ref,
  ) => {
    return (
      <div className="relative">
        <label
          htmlFor={id}
          className={classNames(
            'block text-sm font-medium text-slate-800 mb-1',
            labelClassName ?? '',
          )}
        >
          {label}
        </label>
        <select
          id={id}
          name={id}
          className={classNames(
            FormStyles({ disabled: other.disabled, hasError: false }),
            icon ? 'pl-10' : 'pl-3',
            className || '',
          )}
          {...other}
          ref={ref}
        >
          {children}
        </select>
        {icon && (
          <div
            className={classNames(
              'absolute top-[34px] left-3',
              iconClassName || '',
            )}
          >
            {icon}
          </div>
        )}
      </div>
    )
  },
)

export default FormSelect
