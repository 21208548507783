import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React, { ReactNode, useState } from 'react'

import { classNames } from '../utils/classNames'
import { FormStyles } from './FormStyles'

export type FormInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
  hasError?: ReactNode
  labelClassName?: string
  inputClassName?: string
  clearLabel?: boolean
  wrapperClassName?: string
  optionalLabel?: string
  description?: string | null
  i18n?: {
    optional: string
  }
}

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      id,
      label,
      hasError,
      type,
      defaultValue,
      labelClassName,
      clearLabel,
      children,
      optionalLabel,
      className,
      inputClassName,
      description,
      wrapperClassName,
      i18n,
      ...props
    },
    ref,
  ) => {
    const [shownPassword, togglePassword] = useState<boolean>(false)

    return (
      <div
        className={classNames(
          className || '',
          wrapperClassName ? wrapperClassName : '',
        )}
      >
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              'mb-1 block text-sm font-medium text-slate-900',
              labelClassName || '',
              hasError ? 'text-red-500' : '',
            )}
          >
            {label}
            {optionalLabel ? (
              <span className="text-slate-400"> ({optionalLabel})</span>
            ) : null}

            {!clearLabel && !props.required && (
              <span className="text-slate-400">
                ({i18n?.optional || 'optional'})
              </span>
            )}
          </label>
        )}
        {description && (
          <p className="mb-2 text-sm text-slate-600">{description}</p>
        )}
        <div className="relative h-full rounded-md">
          {children && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
              {children}
            </div>
          )}
          <input
            className={classNames(
              FormStyles({
                disabled: props.disabled,
                hasError: Boolean(hasError),
              }),
              children ? 'pl-10' : 'pl-2.5',
              type === 'password' ? 'pl-10' : '',
              inputClassName ?? '',
            )}
            aria-invalid={Boolean(hasError)}
            aria-describedby={`${id}-error`}
            {...props}
            id={id}
            name={props.name}
            ref={ref}
            type={type === 'password' && shownPassword ? 'text' : type}
            defaultValue={defaultValue}
          />

          {type === 'password' && !hasError && (
            <button
              className="absolute inset-y-0 left-0 flex items-center pl-2.5"
              type="button"
              onClick={() => togglePassword(!shownPassword)}
              tabIndex={-1}
            >
              {shownPassword ? (
                <EyeOffIcon
                  className="h-5 w-5 bg-white text-blue-500"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon
                  className="h-5 w-5 bg-white text-blue-500"
                  aria-hidden="true"
                />
              )}
            </button>
          )}

          {hasError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {hasError && (
          <p className="mt-2 text-sm text-red-500" id={`${id}-error`}>
            {hasError}
          </p>
        )}
      </div>
    )
  },
)

export default FormInput
